import axios from 'axios'
import { ref } from 'vue'

const token = ref(localStorage.getItem('token'))
const user = ref(localStorage.getItem('user'))
const navigation = ref(localStorage.getItem('navigation'))

async function isLoggedIn() {
  try {
    const {data} = await axios.get(import.meta.env.VITE_URL_API + '/auth/validate', {
      headers: {
        Authorization: 'Bearer ' + token.value
      }
    });
  
    return data
    
  } catch (error) {
    return false
  }
  
}

function setToken(tokenValue) {
  localStorage.setItem('token', tokenValue)
  token.value = tokenValue
}

function setUser(userValue) {
  localStorage.setItem('user', JSON.stringify(userValue))
  user.value = userValue
}

function setNavigation(navValue) {
  localStorage.setItem('navigation', JSON.stringify(navValue))
  navigation.value = navValue
}

function getUser() {
  try {
    return JSON.parse(user.value);
  } catch (e) {
    
    return {}
  }
}

function getNavigation() {
  try {
    return JSON.parse(navigation.value);
  } catch (e) {
    
    return {}
  }
}

function clear() {
  localStorage.removeItem('token')
  localStorage.removeItem('user')
  localStorage.removeItem('navigation')
}

export default {
  token: token.value,
  user: user.value,
  navigation: navigation.value,
  setToken,
  setUser,
  setNavigation,
  getUser,
  getNavigation,
  clear,
  isLoggedIn
}