<script setup>

import http from '@/services/http'

const emit = defineEmits(['callAlert', 'updateData'])
const props = defineProps({ remove: { type: Object, required: true } })

function execRemove() {
    const url = props.remove?.url + props.remove?.id
    http.remove(url, emit, (resp) => {
        if (resp.status === 200) {
            updateData()
        }
    })
}

function updateData() {
    http.post(props.remove.url, props.remove.search, emit, (resp) => {
        if (http.success(resp)) {
            emit('updateData', resp.data)
        }
    })
}

</script>

<template>
    <div class="modal fade" id="modalDelete" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"
        aria-labelledby="staticBackdropLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content box">
                <div class="modal-header border border-0">
                    <h5 class="modal-title text-danger"><i class="bi bi-exclamation-octagon-fill me-2"></i> Confimação
                        de Exclusão</h5>
                    <button type="button" class="txt-color ms-auto" data-bs-dismiss="modal" aria-label="Close"><i
                            class="bi bi-x-circle"></i></button>
                </div>
                <div class="modal-body">
                    <h3 class="text-danger text-center"><i class="bi bi-exclamation-octagon fs-1"></i></h3>
                    <p class="text-center p-3">Os dados selecionados serão apagados, sem possibilidade de restauração.
                        Deseja continuar?</p>
                </div>
                <div class="modal-footer border border-0">
                    <button type="button" class="btn btn-outline-secondary" data-bs-dismiss="modal"><i
                            class="bi bi-x-circle me-2"></i> Cancelar</button>
                    <button @click="execRemove" type="button" class="btn btn-outline-danger" data-bs-dismiss="modal"><i
                            class="bi bi-trash me-2"></i> Excluir</button>
                </div>
            </div>
        </div>
    </div>
</template>