import { nextTick } from 'vue';
import html2pdf from 'html2pdf.js';

function load(status = true) {
    const element = document.getElementById('load-wall')
    if (element) {
        status ? element.classList.remove('d-none') : element.classList.add('d-none')
    }
}

function randomCode(len = 12) {
    const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789'
    let final = ""

    for (let i = 0; i < len; i++) {
        final += chars[Math.floor(Math.random() * chars.length)]
    }

    return final
}

function ArrayToText(array, message) {
    try {
        return [...array].join(", ")
    } catch (err) {
        return message
    }
}

function dateNow() {
    return (new Date()).toLocaleString('en-GB', { timeZone: 'America/Fortaleza' })
}

async function exportPDF(data, exportname) {
    try {
        const optionExport = {
            margin: 10,
            filename: exportname+'.pdf',
            image: { type: 'jpeg', quality:0.98},
            html2canvas: { scale: 2 },
            jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait'}
        }
        
        await nextTick()
        html2pdf().from(data).set(optionExport).save()

    } catch (error) {
        console.log(error)
    }
    
}

export default {
    load,
    randomCode,
    ArrayToText,
    dateNow,
    exportPDF
}