import { createRouter, createWebHistory } from 'vue-router'
import auth from '@/stores/auth'

const router = createRouter({
	history: createWebHistory(import.meta.env.BASE_URL),
	routes: [
		{
			path: '/',
			name: 'login',
			component: () => import('../views/LoginView.vue')
		},
		{
			path: '/recover',
			name: 'recover',
			component: () => import('../views/RecoverView.vue')
		},
		{
			path: '/renew/:token?',
			name: 'renew',
			component: () => import('../views/RenewView.vue')
		},
		{
			path: '/services',
			name: 'services',
			component: () => import('../views/ServicesView.vue')
		},
		{
			path: '/home',
			name: 'home',
			meta: { auth: true },
			component: () => import('../views/DashboardView.vue')
		},
		{
			path: '/dashboard',
			name: 'dashboard',
			meta: { auth: true },
			component: () => import('../views/DashboardView.vue')
		},
		{
			path: '/families/:id(\\d+)?',
			name: 'families',
			meta: { auth: true },
			component: () => import('../views/FamiliesView.vue')
		},
		{
			path: '/persons',
			name: 'personsprepare',
			meta: { auth: true },
			component: () => import('../views/PersonsPrepView.vue')
		},
		{
			path: '/persons/:id(\\d+)',
			name: 'persons',
			meta: { auth: true },
			component: () => import('../views/PersonsView.vue')
		},
		{
			path: '/technicians',
			name: 'technicians',
			meta: { auth: true },
			component: () => import('../views/TechniciansView.vue')
		},
		{
			path: '/organs',
			name: 'organs',
			meta: { auth: true },
			component: () => import('../views/OrgansView.vue')
		},
		{
			path: '/units',
			name: 'units',
			meta: { auth: true },
			component: () => import('../views/UnitsView.vue')
		},
		{
			path: '/crases',
			name: 'crases',
			meta: { auth: true },
			component: () => import('../views/CrasesView.vue')
		},
		{
			path: '/management',
			name: 'management',
			meta: { auth: true },
			component: () => import('../views/ManagementView.vue')
		},
		{
			path: '/reports',
			name: 'reports',
			meta: { auth: true },
			component: () => import('../views/ReportsView.vue')
		},
		{
			path: '/:pathMatch(.*)*',
			name: 'notfound',
			component: () => import('../views/NotFoundView.vue')
		}
	]
})

router.beforeEach(async (to) => {
	if (to.meta?.auth) {
		try {
			const isAuthenticaded = await auth.isLoggedIn();

			if (!isAuthenticaded) {
				return {
					path: '/'
				}
			}
		} catch (e) {
			return {
				path: '/'
			}
		}
	}
})

export default router
