import axsi from './axsi'
import forms from './forms'
import utils from '@/utils/utils'

async function request(opt, emit, callresp = null){
    utils.load()
    

    await axsi.axiosInstanceAuth.request(opt).then(resp => {
        if(resp){
            if(callresp){ callresp(resp, emit) }
            response(resp, emit)
            return
        }

        emit('callAlert', {show: true, data:{type:'danger', msg: 'Falha ao receber dados...'}})
        
    }).catch((error) => {
        
        const defresp = callresp ?? response
        emit('callAlert', {show: true, data:error?.response?.data?.notify ?? {type:'danger', msg:'Que feio servidor, não faz assim!'}})
        defresp(error?.response, emit)
        
    }).finally(() => {
        utils.load(false)
    })
}

function response(resp, emit){

    const data = resp.data

    //call redirect
    if(data.redirect){
        window.location = data.redirect
    }

     //call notifys
    if(data.notify){
        emit('callAlert', {show:true, data:data.notify})
    }
}

function post (url, data, emit, resp= null){
    const opt = {
        url: url,
        method: 'POST',
        data : forms.builddata(data)
    }
    
    request(opt, emit, resp)
}

function put (url, data, emit, resp= null){
    const opt = {
        url: url,
        method: 'PUT',
        data : forms.builddata(data)
    }
    
    request(opt, emit, resp)
}

function get (url, emit, resp = null){
    const opt = {
        method: 'GET',
        url: url
    }
    request(opt, emit, resp)
}

function patch (url, data, emit, resp= null){
    const opt = {
        url: url,
        method: 'PATCH',
        data : forms.builddata(data)
    }
    
    request(opt, emit, resp)
}

function remove (url, emit, resp = null){
    const opt = {
        method: 'DELETE',
        url: url
    }
    request(opt, emit, resp)
}

function success(resp){
    return resp?.status === 200 || resp?.status === 201  || resp?.status === 202
}

export default {
    request,
    response,
    post,
    put,
    patch,
    get,
    remove,
    success
}