<script setup>
import { onMounted, ref } from 'vue'
import { RouterView } from 'vue-router'
import style from '@/stores/theme';
import CompModalDelete from '@/components/CompModalDelete.vue';
import CompAlert from './components/CompAlert.vue';

const datalist = ref([])
const remove = ref({})
const alert = ref({ show: false, data: { type: 'success', msg: '' } })

onMounted(() => {
  const screen = document.getElementById('screen')
  if (screen) { screen.classList.add(style.theme) }
})

</script>

<template>

  <div id="load-wall" class="load-wall d-none">
    <img id="load-img" class="load-img" src="./assets/imgs/load.svg">
  </div>

  <div class="container-fluid px-4">
    <RouterView :datalist="datalist" @callAlert="(data) => { alert = data }" @callRemove="(data) => { remove = data }" />
  </div>

  <CompModalDelete :remove="remove" @updateData="(data) => { datalist = data }" />
  <CompAlert :alert="alert" />

</template>